<template>
  <div class="home">

    <br/> 
    
    <img alt="Seal Island Lighthouse" src="../assets/lighthouse.png" class="lighthouse_image">

    <br/>
    <br/>

    <article>
      <div class="news_item" style="text-align: left;">
        <div class="news_title">
          Hello!
        </div>
        <div class="news_description">Next Meeting</div>
        <br/>
        <div>
          <p>The next meeting of the Seal Island Lighthouse Preservation Society will be held Wednesday August 14, 2024 at 7:00 PM online on Zoom, and at the Barrington Municipal Office.</p>
           <ul>
            <li>Topic: SILPS Meeting</li>
            <li>Time: Aug 14, 2024 07:00 PM AST</li>
            <li>Join Zoom Meeting</li>
              <ul>
                <li>https://us02web.zoom.us/j/84378196031?pwd=SMPJlbM21NRNkdk4sTheQoC9mLQhAz.1</li>
                <li>Meeting ID: 843 7819 6031</li>
                <li>Passcode: 374873</li>
              </ul>
          </ul>
        </div>
      </div>
    </article>

    <br/>
    <br/>

    <article>
      <div class="news_item">
        <div class="news_title">
          Save the Historic Seal Island Lighthouse from Demolition by Neglect
        </div>
        <div class="news_description">Please sign the petition here:</div>
        <br/>
        <div>
          <a href="https://chng.it/vxnMPPmV9v" target="blank">https://chng.it/vxnMPPmV9v</a>
          <br/>
          <br/>
          <img alt="Seal Island Lighthouse" src="../assets/changeorg.png" class="news_image">
          <br/>
        </div>
      </div>
    </article>

    <br/>
    <br/>

    <article>
      <div class="news_item">
        <div class="news_title">
          Seal Island Drone Footage
        </div>
        <div class="news_description">September 2023</div>
        <br/>
        <div>
            <video width="100%" controls>
              <source src="../assets/video/Seal Island Lighthouse Drone Video.mp4" type="video/mp4">
              <source src="movie.ogg" type="video/ogg">
              Your browser does not support the video tag.
            </video>
          <br/>
        </div>
      </div>
    </article>
    <br/>
    <br/>
    <br/>

    <div class="news_header">
      <h1>In The News</h1>
    </div>
    

    <br/>

    <article>
      <div class="news_item">
        <div class="news_title">
          Group seeks to preserve historic N.S. lighthouse as DFO builds steel tower replacement
        </div>
        <div class="news_description">CTV News (2024-04-05)</div>
        <br/>
        <div>
          <img alt="Seal Island Lighthouse" src="../assets/ctvnews_lighthouse.jpeg" class="news_image">
          <br/>
          <a href="https://atlantic.ctvnews.ca/more/group-seeks-to-preserve-historic-n-s-lighthouse-as-dfo-builds-steel-tower-replacement-1.6835436" target="blank">https://atlantic.ctvnews.ca/more/group-seeks-to-preserve-historic-n-s-lighthouse-as-dfo-builds-steel-tower-replacement-1.6835436</a>
          <br/>
        </div>
      </div>
    </article>

    <br/>


    <article>
      <div class="news_item">
        <div class="news_title">
         CBC interview with board member Mary McLaren
        </div>
        <div class="news_description">Canada's oldest wooden lighthouse is in disrepair. This N.S. group wants to keep it standing - 
Mainstreet Halifax (2024-03-18)</div>
        <br/>
        <div>
          <p>The Seal Island Lighthouse, which sits about 30 kilometres off southwest Nova Scotia, is Canada's oldest wooden lighthouse. While it's still operating, a group of supporters wants to ensure it keeps standing. Host Jeff Douglas is joined by Mary McLaren, a board member with the Seal Island Lighthouse Preservation Society.</p>
          <img alt="Seal Island Lighthouse" src="../assets/mainstreet.png" class="news_image">
          <br/>
          <a href="https://podcasts.apple.com/ca/podcast/mainstreet-halifax/id404249901?i=1000649627019" target="blank">https://podcasts.apple.com/ca/podcast/mainstreet-halifax/id404249901?i=1000649627019</a>
          <br/>
        </div>
      </div>
    </article>

    <br/>

    <article>
      <div class="news_item">
        <div class="news_title">
         Community group calls on heritage minister to help save remote 200-year-old lighthouse
        </div>
        <div class="news_description">There are plans to replace the Seal Island lighthouse with steel tower (2024-03-17)</div>
        <br/>
        <div>
          <img alt="Seal Island Lighthouse" src="../assets/save_200_year_old_lighthouse.png" class="news_image">
          <br/>
          <a href="https://www.cbc.ca/news/canada/nova-scotia/seal-island-lighthouse-preservation-nova-scotia-1.7146246?cmp=rss" target="blank">https://www.cbc.ca/news/canada/nova-scotia/seal-island-lighthouse-preservation-nova-scotia-1.7146246?cmp=rss</a>
          <br/>
        </div>
      </div>
    </article>

    <br/>

    <article>
      <div class="news_item">
        <div class="news_title">
          Community rallies to save 200-year-old Seal Island lighthouse
        </div>
        <div class="news_description">Lighthouse built in 1831 off the southwestern tip of Nova Scotia is still operating (2023-11-28)</div>
        <br/>
        <div>
          <img alt="Seal Island Lighthouse" src="../assets/communityrallies.png" class="news_image">
          <br/>
          <a href="https://www.cbc.ca/news/canada/nova-scotia/community-rallies-seal-island-lighthouse-1.7037640" target="blank">hhttps://www.cbc.ca/news/canada/nova-scotia/community-rallies-seal-island-lighthouse-1.7037640</a>
          <br/>
        </div>
      </div>
    </article>

    <br/>

    <article>
      <div class="news_item">
        <div class="news_title">
          Petition to the Government of Canada
        </div>
        <div class="news_description">The Seal Island Lighthouse was established in 1831 as a crucial guide for shipping traffic entering and leaving Bay of Fundy area. Strong tides, weather systems and reefs and rocks around the island have made Seal Island a major hazard to shipping for more than three centuries. Those dangers continue to this day (see more)...</div>
        <div class="news_link">
          <a href="https://petitions.ourcommons.ca/en/Petition/Details?Petition=441-01238" target="_blank" title="Petition to the Government of Canada">
            https://petitions.ourcommons.ca/en/Petition/Details?Petition=441-01238
          </a>
          <br/>
        </div>
      </div>
    </article>

    <br/>

    <article>
      <div class="news_item">
        <div class="news_title">
          CBC Radio One Halifax: Mainstreet NS with Jeff Douglas - July 28, 2023<br/>
          The enduring fascination with lighthouses
        </div>
        <div class="news_description">CBC reporter Mariam Mesbah recently visited the Cape Jourimain lighthouse and tells Jeff about what she learned.</div>
        <div class="news_link">
          <a href="https://www.cbc.ca/listen/live-radio/1-37-mainstreet-ns/clip/16000369-the-enduring-fascination-lighthouses" target="_blank" title="The enduring fascination with lighthouses">
            https://www.cbc.ca/listen/live-radio/1-37-mainstreet-ns/clip/16000369-the-enduring-fascination-lighthouses
          </a>
          <br/>
        </div>
      </div>
    </article>

    <br/>

    <article>
      <div class="news_item">
        <div class="news_title">
          CSOS: Grassroots movement afoot to save historic Seal Island Lighthouse in southwestern NS (June 26, 2023)
        </div>
        <div class="news_description">Built in 1831, the Seal Island Lighthouse is North America’s second oldest wooden lighthouse. With the exterior of the structure falling into a state of disrepair, many who frequently visit the island wonder if it will be standing much longer.</div>
        <div>
          <img class="news_image" src="https://saltwire.imgix.net/2023/6/22/sos-grassroots-movement-afoot-to-save-historic-seal-island-l_dm4CPl7.jpg?cs=srgb&fit=crop&h=568&w=847&dpr=1&auto=enhance%2Cformat%2Ccompress" alt="Image of Lighthouse"/>
        </div>
        <div class="news_link">
          <a href="https://www.saltwire.com/atlantic-canada/news/sos-grassroots-movement-afoot-to-save-historic-seal-island-lighthouse-in-southwestern-ns-100867016" target="_blank" title="The enduring fascination with lighthouses">
            https://www.saltwire.com/atlantic-canada/news/sos-grassroots-movement-afoot-to-save-historic-seal-island-lighthouse-in-southwestern-ns-100867016
          </a>
          <br/>
        </div>
      </div>
    </article>

    <br/>

    <article>
      <div class="news_item">
        <div class="news_title">
          2023-08-02 A new door!
        </div>
        <div class="news_description">The Coast Guard was on Seal Island today - they replaced the damaged lighthouse door!</div>
        <br/>
        <div>
          <img alt="Seal Island Lighthouse" src="../assets/new_lighthouse_door.png" class="news_image_small">
          <img alt="Seal Island Lighthouse" src="../assets/new_lighthouse_door2.png" class="news_image_small">
        </div>
      </div>
    </article>

    <br/>

    <article>
      <div class="news_item">
        <div class="news_title">
          'A significant amount of resources is required for repairs' to Seal Island Lighthouse (May 26, 2023)
        </div>
        <div class="news_description">The lighthouse is located off the coast of southwestern Nova Scotia.</div>
        <div>
          <img class="news_image" src="https://saltwire.imgix.net/2023/2/14/demolition-by-neglect-bells-toiling-for-future-of-historic-s_ong3Yvr.jpg" alt="Image of Lighthouse"/>
        </div>
        <div class="news_link">
          <a href="https://www.saltwire.com/atlantic-canada/news/a-significant-amount-of-resources-is-required-for-repairs-to-seal-island-lighthouse-100857716" target="_blank" title="The lighthouse is located off the coast of southwestern Nova Scotia">
            https://www.saltwire.com/atlantic-canada/news/a-significant-amount-of-resources-is-required-for-repairs-to-seal-island-lighthouse-100857716
          </a>
          <br/>
        </div>
      </div>
    </article>

    <br/>

    <article>
      <div class="news_item">
        <div class="news_title">
          'Demolition by neglect' (Feb 14, 2023)
        </div>
        <div class="news_description">Bells toiling for future of historic Seal Island Lighthouse in southwestern NS.</div>
        <div>
          <img class="news_image" src="https://saltwire.imgix.net/2023/5/26/Seal_Island.png?cs=srgb&fit=crop&h=568&w=847&dpr=1&auto=enhance%2Cformat%2Ccompress" alt="Image of Lighthouse"/>
        </div>
        <div class="news_link">
          <a href="https://www.saltwire.com/atlantic-canada/news/demolition-by-neglect-bells-toiling-for-future-of-historic-seal-island-lighthouse-in-southwestern-ns-100824355" target="_blank" title="The lighthouse is located off the coast of southwestern Nova Scotia">
            https://www.saltwire.com/atlantic-canada/news/demolition-by-neglect-bells-toiling-for-future-of-historic-seal-island-lighthouse-in-southwestern-ns-100824355
          </a>
        </div>
      </div>
    </article>

    <br/>
    <br/>
    <br/>
    <!--
    <article>
      <div class="news_item">
        <div class="news_title_cursive">
          <h1>Who's Job Is It?</h1>
        </div>
        <div class="news_description">
          <p>In Canada, the Coast Guard has long been entrusted with the vital responsibility of maintaining lighthouses, ensuring the safety and navigation of maritime traffic along our coasts. However, recent plans to erect a modern steel tower while leaving the historical wooden lighthouse to decay through "demolition by neglect" have raised significant concerns among conservationists and heritage advocates.</p>
          <p>The Department of Fisheries and Oceans, on behalf of the Canadian Coast Guard, is the custodian of many significant heritage buildings, including the oldest lighthouse in North America, the Sambro Island Lighthouse.</p>
          <p>It is disheartening to witness such neglect of an essential part of our maritime history, as these wooden lighthouses hold immense cultural and historical value. The Coast Guard, being the custodians of these structures, should have taken proactive measures to preserve and protect them from the very beginning. Letting them fall into disrepair reflects a lack of appreciation for their significance and a disregard for our shared heritage.</p>
          <p>While embracing progress and modernization is essential, it should not come at the expense of sacrificing our cultural landmarks. The wooden lighthouse represents a tangible link to our maritime past and should be preserved and maintained as a testament to our seafaring traditions.</p>
          <p>In moving forward, it is crucial for the Coast Guard to acknowledge their role as stewards of our coastal heritage and take immediate action to restore and protect the wooden lighthouse, demonstrating a commitment to preserving Canada's rich maritime history for generations to come.</p>
        </div>
      </div>
    </article>
    -->
    <br/>


    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    
</div>
</template>

<style scoped="true">

.lighthouse_image {
  border-radius: 20px;
  width: 45%;
  margin: auto;
}

.news_item, .news_header {
  border-radius: 20px;
  background-color: white;
  max-width: 60%;
  margin: auto;
  padding: 10px;
}
.news_title, .news_title_cursive {
  font-size: 1.8em; 
  font-weight: bold;
  color: black;
  padding: 10px;
}
.news_title_cursive {
  font-family: 'Charm', cursive;
}
.news_image {
  border-radius: 20px;
  max-width: 60%; 
  background-color: white;
  border: solid black 1px;
  margin: auto;
}
.news_image_small {
  border-radius: 20px;
  max-width: 30%; 
  background-color: white;
  border: solid black 1px;
  margin: auto;
  display: inline;
  vertical-align: top;
  margin: 6px;
}
.news_description {
  font-size: 1.4em; 
  font-weight: italic;
  color: gray;
}
.news_link {
  font-size: 1.4em;
  padding: 10px;
}

@media only screen and (max-width: 400px) {
  .news_item {
    max-width: 365px;
  }
  .news_title {
    font-size: 1.2em; 
  }
  .news_link, .news_description {
    font-size: 1em;
  }
  .lighthouse_image {
    width: 300px;
  }
}



</style>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    /*HelloWorld*/
  }
}
</script>
